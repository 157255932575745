import React from "react"
import _ from "lodash"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

import MediaOrderRequestForm from "../../components/MediaBuy/mediaOrderRequestForm"
import {checkAuth} from "../../helpers/auth";

class Request extends React.Component {
    componentDidMount() {
        checkAuth('media-planner');
    }

    render() {
        return (
            <Layout>
                <SEO title="Welcome"/>
                <MediaOrderRequestForm/>
            </Layout>
        )
    }
}

export default Request
