import React, {Component} from "react"
import {Link} from "gatsby"
import _ from "lodash"
import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';
import Grid from "@material-ui/core/Grid"
import Button from "@material-ui/core/Button"
import TextField from "@material-ui/core/TextField"
import MenuItem from "@material-ui/core/MenuItem"
import Icon from "@material-ui/core/Icon"
import moment from 'moment-timezone';

import Select from 'react-select'
import CreatableSelect from "react-select/creatable"
import axios from "axios";

import Loading from "../Miscellaneous/Loading"

import ClientForm from "./Forms/clientForm"

import style from "../Dashboards/style"
import {Popup} from "../Popup"

import qString from "../../../scripts/qString"
import InputLabel from "@material-ui/core/InputLabel";

function NumberFormatCustom(props) {
    const {inputRef, onChange, ...other} = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onChange={e => {
                console.log(e.target.value);
                onChange({
                    target: {
                        value: parseFloat(e.target.value.toString().replace('$', '').replace(/,/g, '') ),
                    }
                });
            }}
            thousandSeparator
            isNumericString
            decimalScale={2}
            fixedDecimalScale
            prefix="$"
        />
    );
}

NumberFormatCustom.propTypes = {
    inputRef: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
};

class MediaBuyRequestForm extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            mbid: _.get(qString, "?id", ""),
            showClientForm: false,
            mainFormDetails: {
                clients: [],
                order_type:'clicks',
                status: {value: 'Live', label: 'Live'},
                start_date: moment().format('YYYY-MM-DD'),
                end_date: moment().format('YYYY-MM-DD'),
            },
        }

        this.handleClientChange = this.handleClientChange.bind(this)
        this.handleClientEditButton = this.handleClientEditButton.bind(this)
        this.handleClientFormSave = this.handleClientFormSave.bind(this)
        this.getClients = this.getClients.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.handleURLChange = this.handleURLChange.bind(this)

        this.updateFormDetails = this.updateFormDetails.bind(this)
        this.handleMediaFormSave = this.handleMediaFormSave.bind(this)
    }

    async componentDidMount() {
        this.setState({loading: true}, async () => {
            // load db
            let data = {}
            if (this.state.mbid) {
                let mediaBuyResponse = await axios.get(`${process.env.SERVER_ENDPOINT}/click_orders/${this.state.mbid}`);
                data = _.get(mediaBuyResponse, "data.click_orders[0]");
                data.start_date = moment.utc(data.start_date).tz('America/New_York').format('YYYY-MM-DD');
                data.end_date = moment.utc(data.end_date).subtract(1, 'd').add(1, 's').tz('America/New_York').format('YYYY-MM-DD');
                data.url_method = { label: data.url_method, value: data.url_method };
                data.status = { label: data.status, value: data.status };
            }

            const { clients } = await this.getClients();
            this.setState(prevState => ({
                loading: false,
                showExtraClientFields: _.get(data, 'client_id') === 1,
                mainFormDetails: {
                    ...prevState.mainFormDetails,
                    ...data,
                    clients,
                },
            }))
        })
    }

    async getClients() {
        try {
            const {data} = await axios.get(process.env.SERVER_ENDPOINT + "/clients")
            return data;
        } catch (error) {
            console.log("Error occurred!")
        }
    }

    handleClientChange(e) {
        const {value} = e

        this.setState({
            showExtraClientFields: value === 1
        })

        if (value === "create_new") {
            this.setState({
                data: false,
                clientFormDetails: false,
                showClientForm: true,
            })
        } else {
            this.updateFormDetails("client_id", value)
        }
    }

    handleClientEditButton(data) {
        this.setState({
            data,
            showClientForm: true,
            clientFormDetails: _.head(
                _.filter(
                    this.state.mainFormDetails.clients,
                    itm => itm.client_id === _.get(data, "value")
                )
            ),
        })
    }

    async handleClientFormSave() {
        const {clientFormDetails, data, mainFormDetails} = this.state
        let {clients} = mainFormDetails
        let client_obj_id = '';
        if (_.has(data, "value")) {
            // update
            const client_id = data.value
            client_obj_id = data.value
            const key = clients.findIndex(itm => itm.client_id === client_id)
            const updateResponse = await axios.put(process.env.SERVER_ENDPOINT + "/clients", clientFormDetails);
            clients = [
                ...clients.slice(0, key),
                updateResponse.data,
                ...clients.slice(key + 1),
            ]
        } else {
            // create
            const createResponse = await axios.post(process.env.SERVER_ENDPOINT + "/clients", clientFormDetails);
            client_obj_id = createResponse.data.client_id;
            clients = [...clients, createResponse.data]
        }

        this.setState({
            mainFormDetails: {
                ...mainFormDetails,
                clients,
                client_id: client_obj_id
            },
            showClientForm: false,
        })
    }

    handleChange = selectedOption => {
        this.updateFormDetails('status', selectedOption);
    };
    handleURLChange = selectedOption => {
        this.updateFormDetails('url_method', selectedOption);
    };

    updateFormDetails(key, value) {
        this.setState(prevState => ({
            mainFormDetails: {
                ...prevState.mainFormDetails,
                [key]: value,
            },
        }), () => {
            let form = {...this.state.mainFormDetails};
            switch(key) {
                case 'leads_per_day':
                    form.daily_budget = form.cost_per_lead * value
                break;
    
                case 'daily_budget':
                    form.leads_per_day = form.daily_budget / form.cost_per_lead
                break;
            }

            this.setState({
                mainFormDetails: form
            })
        })
    }

    async handleMediaFormSave() {
        // gather data
        const {mainFormDetails, mbid} = this.state
        const {status, url_method} = mainFormDetails;
        
        const dates = {
            start_date: moment.tz(mainFormDetails.start_date,'America/New_York').utc(),
            end_date: moment.tz(mainFormDetails.end_date,'America/New_York').add(1, 'd').subtract(1, 's').utc(),
        }
        // update
        if (mbid) {
            const updateResponse = await axios.put(process.env.SERVER_ENDPOINT + "/click_orders", {
                click_order_id: parseInt(mbid),
                ..._.omit(mainFormDetails, [
                    "clients",
                    "createdAt",
                    "updatedAt",
                    "status",
                    "url_method"
                ]),
                ...dates,
                status: status.label,
                url_method: url_method.label
            })

            console.log(updateResponse)
        } else {

            const formValues = {
                ..._.omit(mainFormDetails, [
                    "clients",
                    "status",
                    "url_method"
                ]),
                ...dates,
                status: status.label,
                url_method: url_method.label
            };

            const createResponse = await axios.post(process.env.SERVER_ENDPOINT + "/click_orders", formValues)

            console.log({createResponse})
        }

        // create
        if (typeof window === "object") window.location.href = "/media-planner/media-orders";
    }

    render() {
        let disabledCostsAttrs = {};
        if(!_.get(this.state, "mainFormDetails.cost_per_lead") > 0) disabledCostsAttrs.disabled = true
        const {loading, mainFormDetails} = this.state
        const {url_method, status, start_date, end_date} = mainFormDetails;
        const url_methods = [
            {value: 'GET', label: 'GET'},
            {value: 'POST', label: 'POST'},
        ]
        const status_labels = [
            {value: 'Live', label: 'Live'},
            {value: 'Paused', label: 'Paused'},
            {value: 'Draft', label: 'Draft'},
        ]
        const Option = props => (
            <Grid container>
                <Grid item xs={11}>
                    <MenuItem
                        buttonRef={props.innerRef}
                        selected={props.isFocused}
                        component="div"
                        style={{
                            fontWeight: props.isSelected ? 500 : 400,
                            flex: "1 0 90%",
                        }}
                        {...props.innerProps}
                    >
                        {props.children}
                    </MenuItem>
                </Grid>
                {
                    (_.get(props, "data.value") !== "create_new") && 
                    !(_.get(props, 'data.value') === 6 && _.get(props, 'data.label') === 'Traders Agency') && (
                        <Grid
                            xs={1}
                            style={{padding: 10, cursor: "pointer"}}
                            onClick={() => props.selectProps.onEditClick(props.data)}
                        >
                            <Icon>edit</Icon>
                        </Grid>
                    )
                }
            </Grid>
        )

        const components = {
            Option,
        }
        const clientOptions = [
            ..._.map(this.state.mainFormDetails.clients, itm => ({
                id: itm.client_id,
                label: itm.client_name,
            })),
            { id: "create_new", label: "Create New Client" },
        ].map(itm => ({value: itm.id, label: itm.label}))

        const orderTypeOptions = [{value:'clicks',label:'Clicks'},{value:'opens',label:'Opens'}];

        if (loading) return <Loading/>

        return (
            <div style={style.mainContainer}>

                <Popup
                    open={this.state.showClientForm}
                    title="Client Details"
                    mainFormDetails={this.state.mainFormDetails}
                    clientFormDetails={this.state.clientFormDetails}
                    data={this.state.data}
                    onContentUpdate={itm =>
                        this.setState(prevState => ({
                            clientFormDetails: {...prevState.clientFormDetails, ...itm},
                        }))
                    }
                    onSave={this.handleClientFormSave}
                    onCancel={() => this.setState({showClientForm: false})}
                    Component={ClientForm}
                />

                <h1 style={{textAlign: "center", padding: 20}}>
                    Co-Registration Order Form
                </h1>

                <Grid
                    container
                    justify="center"
                    style={{width: 650, margin: "0 auto"}}
                >

                    <Grid container style={{marginTop: 20, border: '1px solid #F1F1F1', padding: 20}}>
                        <Grid container>
                            <Grid item xs={4} style={style["mt-8"]}>
                                <InputLabel htmlFor="filter">Client</InputLabel>
                            </Grid>
                            <Grid item xs={8}>
                                <CreatableSelect
                                    name="clientSelect"
                                    components={components}
                                    placeholder="Select a Client"
                                    options={clientOptions}
                                    onChange={this.handleClientChange}
                                    onEditClick={this.handleClientEditButton}
                                    value={_.head(
                                        clientOptions.filter(
                                            itm => itm.value === _.get(mainFormDetails, "client_id")
                                        )
                                    )}
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container>
                                <Grid item xs={4} style={style["mt-15"]}>
                                    <InputLabel htmlFor="filter">Client Campaign Name</InputLabel>
                                </Grid>
                                <Grid item xs={8}>
                                    <TextField
                                        variant="outlined"
                                        margin="dense"
                                        style={{width: "100%"}}
                                        value={_.get(mainFormDetails, "client_campaign_name")}
                                        onChange={e =>
                                            this.updateFormDetails("client_campaign_name", e.target.value)
                                        }
                                    />
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={4} style={style["mt-8"]}>
                                    <InputLabel htmlFor="filter">Order Type</InputLabel>
                                </Grid>
                                <Grid item xs={8}>
                                    <Select
                                        name="order_type"
                                        placeholder="Select Order Type"
                                        options={orderTypeOptions}
                                        onChange={e => this.updateFormDetails("order_type", e.value)}
                                        value={_.head(
                                            orderTypeOptions.filter(
                                                itm => itm.value === _.get(mainFormDetails, "order_type")
                                            )
                                        ) || 'clicks'}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={4} style={style["mt-15"]}>
                                    <InputLabel htmlFor="filter">Post Back Url</InputLabel>
                                </Grid>
                                <Grid item xs={8}>
                                    <div style={{marginBottom: 10}}>
                                        <TextField
                                            variant="outlined"
                                            margin="dense"
                                            style={{width: "100%"}}
                                            value={_.get(mainFormDetails, "post_back_url")}
                                            onChange={e =>
                                                this.updateFormDetails("post_back_url", e.target.value)
                                            }
                                        />
                                    </div>
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={4} style={style["mt-8"]}>
                                    <InputLabel htmlFor="filter">URL Method</InputLabel>
                                </Grid>
                                <Grid item xs={8}>
                                    <div style={{marginBottom: 10}}>
                                        <Select
                                            value={url_method}
                                            onChange={this.handleURLChange}
                                            options={url_methods}
                                        />
                                    </div>

                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={2} style={style["mt-15"]}>
                                    <InputLabel htmlFor="filter">CPL</InputLabel>
                                </Grid>
                                <Grid item xs={3}>
                                    <div style={{marginBottom: 10}}>
                                        <TextField
                                            variant="outlined"
                                            margin="dense"
                                            style={{width: "100%"}}
                                            onChange={e =>
                                                this.updateFormDetails(
                                                    "cost_per_lead",
                                                    parseFloat(e.target.value || 0)
                                                )
                                            }
                                            id="formatted-numberformat-input"
                                            InputProps={{
                                                inputComponent: NumberFormatCustom,
                                            }}
                                            value={_.get(this.state, "mainFormDetails.cost_per_lead")}
                                        />
                                    </div>
                                </Grid>
                                <Grid item xs={1}/>
                                <Grid item xs={3} style={style["mt-15"]}>
                                    <InputLabel htmlFor="filter">Clicks Ordered</InputLabel>
                                </Grid>
                                <Grid item xs={3}>
                                    <div style={{marginBottom: 10}}>
                                        <TextField
                                            variant="outlined"
                                            margin="dense"
                                            type="number"
                                            style={{width: "100%"}}
                                            value={_.get(mainFormDetails, "clicks_ordered")}
                                            onChange={e =>
                                                this.updateFormDetails("clicks_ordered", e.target.value)
                                            }
                                        />
                                    </div>
                                </Grid>
                            </Grid>
                            
  
                            <InputLabel style={{fontWeight: 400}} htmlFor="filter">Daily Cap</InputLabel>
                            <Grid item style={{border: '1px solid #F1F1F1', padding: 10}}>

                                <Grid container>
                                    <Grid item xs={4} style={style["mt-15"]}>
                                        <InputLabel htmlFor="filter">Leads per day</InputLabel>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <div style={{marginBottom: 10}}>
                                            <TextField
                                                variant="outlined"
                                                margin="dense"
                                                type="number"
                                                style={{width: "100%"}}
                                                value={_.get(mainFormDetails, "leads_per_day")}
                                                {...disabledCostsAttrs}
                                                onChange={e =>
                                                    this.updateFormDetails("leads_per_day", e.target.value)
                                                }
                                            />
                                        </div>
                                    </Grid>
                                </Grid>
                                <Grid item xs={1} style={{border: '1px solid #F1F1F1', padding: 10}}>
                                    <InputLabel htmlFor="filter">OR</InputLabel>
                                </Grid>
                                <Grid container>
                                    <Grid item xs={4} style={style["mt-15"]}>
                                        <InputLabel htmlFor="filter">Daily Budget</InputLabel>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <div style={{marginBottom: 10}}>
                                            <TextField
                                                variant="outlined"
                                                margin="dense"
                                                style={{width: "100%"}}
                                                {...disabledCostsAttrs}
                                                onChange={e => {
                                                    this.updateFormDetails(
                                                        "daily_budget",
                                                        parseFloat(e.target.value || 0)
                                                    )
                                                }
                                                }
                                                id="formatted-numberformat-input"
                                                InputProps={{
                                                    inputComponent: NumberFormatCustom,
                                                }}
                                                value={_.get(this.state, "mainFormDetails.daily_budget")}
                                            />
                                        </div>
                                
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid item xs={4} style={style["mt-15"]}>
                                <InputLabel htmlFor="filter">Start Date</InputLabel>
                            </Grid>
                            <Grid item xs={8}>
                                <div style={{marginBottom: 10}}>
                                    <TextField
                                        variant="outlined"
                                        margin="dense"
                                        type="date"
                                        placeholder="Start Date"
                                        defaultValue={start_date}
                                        style={{width: "100%"}}
                                        value={_.get(mainFormDetails, "start_date")}
                                        onChange={e =>
                                            this.updateFormDetails("start_date", e.target.value)
                                        }
                                    />
                                </div>
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid item xs={4} style={style["mt-15"]}>
                                <InputLabel htmlFor="filter">Fulfill By</InputLabel>
                            </Grid>
                            <Grid item xs={8}>
                                <div style={{marginBottom: 10}}>
                                    <TextField
                                        variant="outlined"
                                        margin="dense"
                                        type="date"
                                        placeholder="Fulfill By"
                                        defaultValue={end_date}
                                        style={{width: "100%"}}
                                        value={_.get(mainFormDetails, "end_date")}
                                        onChange={e =>
                                            this.updateFormDetails("end_date", e.target.value)
                                        }
                                    />
                                </div>
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid item xs={4} style={style["mt-8"]}>
                                <InputLabel htmlFor="filter">Status</InputLabel>
                            </Grid>
                            <Grid item xs={8}>
                                <div style={{marginBottom: 10}}>
                                    <Select
                                        value={status}
                                        onChange={this.handleChange}
                                        options={status_labels}
                                        defaultValue={{value: 'Live', label: 'Live'}}
                                    />
                                </div>
                            </Grid>
                        </Grid>

                    </Grid>
                    <Grid container style={{width: 400, marginTop: 20}}>
                        <Grid item xs={5} style={{textAlign: "center"}}>
                            <Link to="/media-planner/media-orders">
                                <Button style={{width: 200}} variant="contained">Cancel</Button>
                            </Link>
                        </Grid>
                        <Grid item xs={2}/>
                        <Grid item xs={5} style={{textAlign: "center"}}>
                            <Button
                                onClick={this.handleMediaFormSave}
                                color="primary"
                                variant="contained"
                                style={{width: 200}}
                            >
                                Save
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>


            </div>
        )
    }
}

export default MediaBuyRequestForm
